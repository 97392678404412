import { notification } from "antd";
import request from "./http-request";
import config from "./config";

const lib = {};
const key = "notification";

const setHeader = () => {
  const user = JSON.parse(localStorage.getItem(config.key.user));
  return user
    ? {
      headers: {
        Authorization: "Bearer " + user?.jwt,
      },
    }
    : {};
};

lib.sendItemDoc = async (data) => myRequestObj("saveItemDoc").post(data);

lib.verifyPaymentFlutter = async (data) =>
  myRequestObj("verifyPayment").post(data);

lib.register = async (data) => myRequestObj("regUser").post(data);

lib.loginSpecial = async (data) => myRequestObj("login&special").post(data);

lib.login = async (data) => myRequestObj("loginClaims").post(data);

lib.createUser = async (data) => myRequestObj("fCreateUser").post(data);

lib.readFacilityUser = async (data) =>
  await (
    await myRequestObj("fUser").post(data)
  ).data;

lib.updateProfile = async (data) =>
  await (
    await myRequestObj("fChangeStatus").post(data)
  ).data;

lib.reset = async (data) => await (await myRequestObj("reset").post(data)).data;

lib.saveNewProfile = async (data) =>
  await (
    await myRequestObj("save-new-profile").post(data)
  ).data;

// used for verifying clients from claims
lib.getClient = async (data) =>
  await (
    await myRequestObj("validateUserClaims").post(data)
  ).data;

lib.checkin = async (data) =>
  await (
    await myRequestObj("clientCheckin").post(data)
  ).data;

lib.checkinList = async (data) =>
  await (
    await myRequestObj("checkinList").post(data)
  ).data;

lib.sendPA = async (data) =>
  await (
    await myRequestObj("paRequest").post(data)
  ).data;

lib.getCostableCategories = async () =>
  await (
    await myRequestObj("constableCategoriesList").post()
  ).data;

lib.getPAData = async (claimId) =>
  await (
    await myRequestObj("approvedPACheck").post(claimId)
  ).data;

export const myRequest = async (type = "post", uri = "index", data = {}) => {
  let url = `?${uri}`;
  try {
    if (type === "get") {
      const resp = await request.get(url, data, setHeader());
      if (resp?.data?.error) {
        notification.error({ key, message: resp?.data?.message });
        return { error: 1, data: [], message: resp?.data?.message };
      }
      return resp;
    } else {
      const resp = await request.post(url, data, setHeader());
      if (resp?.data?.error) {
        notification.error({ key, message: resp?.data?.message });
        return { error: 1, data: [], message: resp?.data?.message };
      }
      return resp;
    }
  } catch (e) {
    console.log(e?.response?.data?.msg || e?.message);
    return {
      status: "error",
      msg: e?.response?.data?.msg || e?.message,
      data: [],
    };
  }
};

export const myRequestObj = (uri = "index") => {
  const url = `?${uri}`;
  return {
    get: async (data = {}) => {
      try {
        const resp = await request.get(url, data, setHeader());
        const error = parseInt(resp?.data?.error)
        if (error) {
          notification.error({
            key,
            message: resp?.data?.message,
            placement: "topLeft",
          });
          if (error === 10) {
            window.location.assign('/logout');
          }
          return { error: 1, data: [], message: resp?.data?.message };
        }
        return resp;
      } catch (e) {
        notification.error({
          key,
          message: e?.response?.data?.msg || e?.message,
          placement: "topLeft",
        });

        return { error: 1, status: "error", msg: e?.response?.data?.msg || e?.message };
      }
    },
    post: async (data = {}) => {
      try {
        const resp = await request.post(url, data, setHeader());
        const error = parseInt(resp?.data?.error)
        if (parseInt(resp?.data?.error)) {

          if (error === 10) {
            notification.error({
              key,
              message: "Your Current Seesion has expired. You need to login to continue.",
              placement: "topLeft",
            });
            setTimeout(window.location.assign('/logout'), 2000)
          } else {
            notification.error({
              key,
              message: resp?.data?.message,
              placement: "topLeft",
            });
          }
          return { error: 1, data: [], message: resp?.data?.message };
        }
        return resp;
      } catch (e) {
        notification.error({
          key,
          message: e?.response?.data?.msg || e?.message,
          placement: "topLeft",
        });

        return { status: "error", msg: e?.response?.data?.msg || e?.message };
      }
    },
  };
};

export async function* fetchPaginatedData(
  method = "post",
  uri = "index",
  payload = {}
) {
  let page = 1;
  let totalPages = 1;

  do {
    const response = await myRequest(method, uri, { ...payload, page });
    const responseData = await response.data;

    //   Quit operation on error
    if (responseData?.error) {
      break;
    }

    const { pages, data } = responseData?.data;
    ++page;
    totalPages = pages;
    yield data;
  } while (page <= totalPages);
}

export function removeDuplicates(array, property) {
  return array.filter(
    (obj, index, self) =>
      !self.slice(index + 1).find((item) => item[property] === obj[property])
  );
}

export function isEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export function updateChangedObjects(oldObjects, newObjects) {
  const oldObjectMap = {};
  const updatedObjects = [];

  // Create a map of old objects indexed by id
  for (const oldObj of oldObjects) {
    oldObjectMap[oldObj.id] = oldObj;
  }

  // Iterate through new objects and compare with corresponding old objects
  for (const newObj of newObjects) {
    const oldObj = oldObjectMap[newObj.id];

    if (!oldObj || !isEqual(oldObj, newObj)) {
      updatedObjects.push(newObj);
    } else {
      updatedObjects.push(oldObj); // Push unchanged objects
    }
  }

  return updatedObjects;
}

export default lib;
